import routerOptions0 from "/Users/guimauve/Sites/azimut-site/nuxt/node_modules/nuxt/dist/pages/runtime/router.options";
import routerOptions1 from "/Users/guimauve/Sites/azimut-site/nuxt/app/router.options.js";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}