import { defineNuxtPlugin } from '#app/nuxt'
import { LazyButton, LazyFormieAlert, LazyFormieField, LazyFormieForm, LazyFormieInputAddress, LazyFormieInputAgree, LazyFormieInputCategories, LazyFormieInputCheckboxes, LazyFormieInputDate, LazyFormieInputDropdown, LazyFormieInputEmail, LazyFormieInputEntries, LazyFormieInputFileUpload, LazyFormieInputGroup, LazyFormieInputHeading, LazyFormieInputHidden, LazyFormieInputHtml, LazyFormieInputMultiLineText, LazyFormieInputName, LazyFormieInputNumber, LazyFormieInputPhone, LazyFormieInputRadio, LazyFormieInputRecipients, LazyFormieInputRepeater, LazyFormieInputSection, LazyFormieInputSingleLineText, LazyFormieInputTable, LazyFormieInputTags, LazyFormieInputUsers, LazyFormieInstructions, LazyFormieLabel, LazyFormieLanding, LazyFormiePage, LazyMainLink, LazyFlexibleBackLinks, LazyFlexibleBannerCentral, LazyFlexibleFeaturedRealisation, LazyFlexible, LazyFlexibleForm, LazyFlexibleHero, LazyFlexibleImageFull, LazyFlexiblePanels, LazyFlexibleRealisationsList, LazyFlexibleSliderImages, LazyFlexibleTextContent, LazyFlexibleTextImage, LazyFlexibleTitleSubtitle, LazyFlexibleTitleText, LazyFlexibleWarningBanner, LazyParallaxChild, LazyParallax, LazyPartialsFooter, LazyPartialsHeader, LazyPartialsNavMobile, LazyProjectInfos, LazySplitText, LazyVisibleProgress } from '#components'
const lazyGlobalComponents = [
  ["Button", LazyButton],
["FormieAlert", LazyFormieAlert],
["FormieField", LazyFormieField],
["FormieForm", LazyFormieForm],
["FormieInputAddress", LazyFormieInputAddress],
["FormieInputAgree", LazyFormieInputAgree],
["FormieInputCategories", LazyFormieInputCategories],
["FormieInputCheckboxes", LazyFormieInputCheckboxes],
["FormieInputDate", LazyFormieInputDate],
["FormieInputDropdown", LazyFormieInputDropdown],
["FormieInputEmail", LazyFormieInputEmail],
["FormieInputEntries", LazyFormieInputEntries],
["FormieInputFileUpload", LazyFormieInputFileUpload],
["FormieInputGroup", LazyFormieInputGroup],
["FormieInputHeading", LazyFormieInputHeading],
["FormieInputHidden", LazyFormieInputHidden],
["FormieInputHtml", LazyFormieInputHtml],
["FormieInputMultiLineText", LazyFormieInputMultiLineText],
["FormieInputName", LazyFormieInputName],
["FormieInputNumber", LazyFormieInputNumber],
["FormieInputPhone", LazyFormieInputPhone],
["FormieInputRadio", LazyFormieInputRadio],
["FormieInputRecipients", LazyFormieInputRecipients],
["FormieInputRepeater", LazyFormieInputRepeater],
["FormieInputSection", LazyFormieInputSection],
["FormieInputSingleLineText", LazyFormieInputSingleLineText],
["FormieInputTable", LazyFormieInputTable],
["FormieInputTags", LazyFormieInputTags],
["FormieInputUsers", LazyFormieInputUsers],
["FormieInstructions", LazyFormieInstructions],
["FormieLabel", LazyFormieLabel],
["FormieLanding", LazyFormieLanding],
["FormiePage", LazyFormiePage],
["MainLink", LazyMainLink],
["FlexibleBackLinks", LazyFlexibleBackLinks],
["FlexibleBannerCentral", LazyFlexibleBannerCentral],
["FlexibleFeaturedRealisation", LazyFlexibleFeaturedRealisation],
["Flexible", LazyFlexible],
["FlexibleForm", LazyFlexibleForm],
["FlexibleHero", LazyFlexibleHero],
["FlexibleImageFull", LazyFlexibleImageFull],
["FlexiblePanels", LazyFlexiblePanels],
["FlexibleRealisationsList", LazyFlexibleRealisationsList],
["FlexibleSliderImages", LazyFlexibleSliderImages],
["FlexibleTextContent", LazyFlexibleTextContent],
["FlexibleTextImage", LazyFlexibleTextImage],
["FlexibleTitleSubtitle", LazyFlexibleTitleSubtitle],
["FlexibleTitleText", LazyFlexibleTitleText],
["FlexibleWarningBanner", LazyFlexibleWarningBanner],
["ParallaxChild", LazyParallaxChild],
["Parallax", LazyParallax],
["PartialsFooter", LazyPartialsFooter],
["PartialsHeader", LazyPartialsHeader],
["PartialsNavMobile", LazyPartialsNavMobile],
["ProjectInfos", LazyProjectInfos],
["SplitText", LazySplitText],
["VisibleProgress", LazyVisibleProgress],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
