<script setup>
import { animate, stagger } from 'motion';

const {
    // setWindowSizes, scrollTop, navOpened, subnavOpened, submenuOpened, langCookie, loaded, loadingShown,
    setWindowSizes, scrollTop, navOpened, subnavOpened, submenuOpened, loaded, loadingShown,
} = useClientState();
const {
    setNavs, setGlobals, activeSite, setActiveSite, sites,
} = useSharedState();

const route = useRoute();
const router = useRouter();
const lenis = useLenis();

if (route?.query?.token && route?.query?.['x-craft-live-preview']) {
    useGqlHost(`?x-craft-live-preview=${route.query['x-craft-live-preview']}&token=${route.query.token}`);
}

const offsetClasses = ref('[--app-offset-top:5.5rem] lg:[--app-offset-top:0rem]');

// Not working somehow?
// const langCookieRef = useCookieLocale();
// const langCookieRef = useCookie('i18n_redirected');
// langCookie.value = langCookieRef.value;

router.beforeEach((to, redirect, next) => {
    navOpened.value = false;
    subnavOpened.value = false;
    submenuOpened.value = null;
    if (to.hash) {
        const targetElement = document.querySelector(to.hash);
        if (targetElement) {
            lenisScrollTo(targetElement, {
                onComplete: next,
            });
        }
    } else {
        next();
    }
});

// Unable to make this reactive directly in store?
watchEffect(() => {
    const path = route?.path ?? '';
    let detectedSite = sites.value.fr_CA;
    if (path) {
        const realPath = path.replace(/^\//, '');
        const siteKeys = Object.keys(sites.value);
        for (let i = 0, il = siteKeys.length; i < il; ++i) {
            const siteKey = siteKeys[i];
            const site = sites.value[siteKey];
            if (realPath.startsWith(site.prefix)) {
                detectedSite = site;
            }
        }
    }
    setActiveSite(detectedSite);
});

const pageTransitionWrapper = ref(null);
const pageTransitions = {
    name: 'guimauve-page',
    mode: 'out-in',
    css: false,
    onBeforeLeave: () => {
        const layers = pageTransitionWrapper.value.querySelectorAll('.page-transition-layer');
        pageTransitionWrapper.value.style.transform = 'translateY(0vh) translateZ(0)';
        for (let i = 0, il = layers.length; i < il; ++i) {
            const layer = layers[i];
            layer.style.transform = 'translateY(120%)';
        }
    },
    onLeave: (el, done) => {
        const layers = pageTransitionWrapper.value.querySelectorAll('.page-transition-layer');
        const anim = animate(layers, {
            transform: ['translateY(120%) translateZ(0)', 'translateY(-150%) translateZ(0)'],
        }, {
            duration: 1,
            delay: stagger(0.2),
            easing: [0.14, 0.47, 0.45, 0.94],
        });

        anim.finished.then(() => {
            debugger;
            done();
        });
    },
    onEnter: (el, done) => {
        const layers = pageTransitionWrapper.value.querySelectorAll('.page-transition-layer');
        const anim = animate(layers, {
            transform: ['translateY(-150%) translateZ(0)', 'translateY(-720%) translateZ(0)'],
        }, {
            duration: 1,
            delay: stagger(0.2),
            easing: [0.14, 0.47, 0.45, 0.94],
        });

        anim.finished.then(() => {
            pageTransitionWrapper.value.style.transform = 'translateY(0vh) translateZ(0)';
            done();
        });
    },
};

/**
 * SEO META
 * <meta content="en_CA" property="og:locale">
 * <meta content="fr_CA" property="og:locale:alternate">
 * <MainLink href="https://guimauvecreative.com" rel="canonical">
 * <MainLink href="https://guimauvecreative.com/fr-ca" rel="alternate" hreflang="fr-ca">
 */
/**
 * <link rel="apple-touch-icon" sizes="180x180" href="favicon/apple-touch-icon.png">
   <link rel="icon" type="image/png" sizes="32x32" href="favicon/favicon-32x32.png">
   <link rel="icon" type="image/png" sizes="16x16" href="favicon/favicon-16x16.png">
   <link rel="manifest" href="favicon/site.webmanifest">
   <link rel="mask-icon" href="favicon/safari-pinned-tab.svg" color="#5bbad5">
   <meta name="msapplication-TileColor" content="#da532c">
   <meta name="theme-color" content="#ffffff">
 */
useHead({
    meta: [
        {
            property: 'og:locale', content: activeSite.value.lang,
        },
        {
            name: 'msapplication-TileColor', content: '#da532c',
        },
        {
            name: 'theme-color', content: '#ffffff',
        },
    ],
    link: [
        { rel: 'apple-touch-icon', sizes: '180x180', href: '/favicons/apple-touch-icon.png' },
        {
            rel: 'icon', type: 'image/png', sizes: '32x32', href: '/favicons/favicon-32x32.png',
        },
        {
            rel: 'icon', type: 'image/png', sizes: '16x16', href: '/favicons/favicon-16x16.png',
        },
        { rel: 'manifest', href: '/favicons/site.webmanifest' },
    ],
});

onMounted(() => {
    if (!isServer()) {
        setWindowSizes();
        loaded.value = true;
        scrollTop.value = lenis.value.scroll;
    }
});

// const selectedSite = ref(activeSite.value.metalang);

// const switchSite = async () => {
//     await setLocale(selectedSite.value);
// };

/**
 * Mettre à jour les données des navigations et des globales
 * selon la langue du site sélectionné.
 *
 * @todo    Mettre à jour seulement au milieu de la transition de page pour éviter un "jump" pendant la navigation?
 */
const { data: dataNavs, refresh: refreshNavs } = await useAsyncData('dataNavs', () => GqlFetchNav());
const { data: dataGlobals, refresh: refreshGlobals } = await useAsyncData('dataGlobals', () => GqlFetchGlobals({ lang: activeSite.value.lang }));
//
watch(activeSite, async () => {
    await refreshGlobals();
    await refreshNavs();

    setNavs(dataNavs.value);
    setGlobals(dataGlobals.value);
});

setNavs(dataNavs.value);
setGlobals(dataGlobals.value);
</script>
<template>
    <div :class="offsetClasses">
        <NuxtLayout>
            <NuxtPage :transition="pageTransitions" />
        </NuxtLayout>
        <DevOnly>
            <div class="backface-hidden fixed bottom-0 left-0 z-50 flex w-12 items-center justify-center bg-neutral-800 text-xs text-white">
                <div class="block sm:hidden">
                    xs
                </div>
                <div class="hidden sm:block md:hidden">
                    sm
                </div>
                <div class="hidden md:block lg:hidden">
                    md
                </div>
                <div class="hidden lg:block xl:hidden">
                    lg
                </div>
                <div class="hidden xl:block 2xl:hidden">
                    xl
                </div>
                <div class="hidden 2xl:block 3xl:hidden">
                    2xl
                </div>
                <div class="hidden 3xl:block">
                    3xl
                </div>
            </div>
        </DevOnly>
        <div

            ref="pageTransitionWrapper"
            class="page-transition-wrapper size-screen pointer-events-none fixed left-0 top-0 z-50 translate-y-full"
            aria-hidden
        >
            <div class="page-transition-layer absolute left-0 top-0 size-full">
                <div class="relative bottom-[-1px] w-screen">
                    <svg
                        width="1920"
                        height="1399"
                        viewBox="0 0 1920 1399"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-auto w-screen"
                    >
                        <path d="M0 1398.43L936.412 13.3951C949.807 -4.46505 972.132 -4.46505 985.527 13.3951L1920 1398.43H0Z" fill="#E20613" />
                    </svg>
                </div>
                <div class="h-[200vh] w-screen bg-carmin" />
                <div class="top-[-1px] w-screen">
                    <svg
                        width="1920"
                        height="1705"
                        viewBox="0 0 1920 1705"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-auto w-screen"
                    >
                        <path d="M1920 0H0V1704.43L936.412 319.395C949.807 301.535 972.132 301.535 985.527 319.395L1920 1701.57V0Z" fill="#E20613" />
                    </svg>
                </div>
            </div>
            <!-- <div class="page-transition-layer absolute left-0 top-10 size-full bg-nuit" /> -->
        </div>
    </div>
    <div v-show="loadingShown" class="loading-screen pointer-events-none fixed left-0 top-0 z-100 flex size-full items-center justify-center bg-nuit text-white" />
</template>
