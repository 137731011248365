const lenis = useLenis();

const baseName = !isServer() ? window.baseName : null || 'http://guimauve.test';

const botRegex = /bot|googlebot|crawler|spider|robot|crawling|prerender|lighthouse/i;
const isBot = ref(!isServer() && navigator.userAgent && botRegex.test(navigator.userAgent));
const scrolledSmall = ref(false);
const scrolledLarge = ref(false);
const footerVisible = ref(false);
const scrollTop = ref(0);
const pin = ref(0);
const pinnedDistance = ref(0);
const scrollDirection = ref(0);
const innerWidth = ref(isServer() ? 1920 : window.innerWidth);
const innerHeight = ref(isServer() ? 1080 : window.innerHeight);
const navOpened = ref(false);
const subnavOpened = ref(false);
const submenuOpened = ref(null);
const firstPanelIsBlue = ref(true);
const langCookie = ref(null);
const scrollHeight = ref(0);
const loaded = ref(false);
const loadingShown = ref(true);

const countryPanelIsVisible = computed(() => {
    let res = true;
    if (langCookie.value) {
        res = false;
    }
    return res;
});

const setWindowSizes = () => {
    innerWidth.value = window.innerWidth;
    innerHeight.value = window.innerHeight;
    scrollHeight.value = document.body.scrollHeight;
};
const debouncedResize = debounce(setWindowSizes, 500);

// Lenis only gets created on client-side
if (!isServer()) {
    if (lenis.value) {
        lenis.value.on('scroll', () => {
            scrollTop.value = lenis.value.scroll;
            if (scrollDirection.value !== lenis.value.direction) {
                pin.value = scrollTop.value;
            }
            scrollDirection.value = lenis.value.direction;
            pinnedDistance.value = Math.abs(pin.value - scrollTop.value);
            scrolledSmall.value = scrollTop.value > 300 && pinnedDistance.value > 200 && scrollDirection.value === 1;
            scrolledLarge.value = scrollTop.value > 300 && pinnedDistance.value > 1200 && scrollDirection.value === 1;
            footerVisible.value = scrollTop.value > scrollHeight.value - innerHeight.value * 1.5;
        });
    } else {
        window.addEventListener('scroll', () => {
            if (scrollDirection.value !== (window.scrollY > scrollTop.value ? 1 : -1)) {
                pin.value = scrollTop.value;
            }
            scrollDirection.value = window.scrollY > scrollTop.value ? 1 : -1;
            scrollTop.value = window.scrollY;
            pinnedDistance.value = Math.abs(pin.value - scrollTop.value);
            scrolledSmall.value = scrollTop.value > 300 && pinnedDistance.value > 200 && scrollDirection.value === 1;
            scrolledLarge.value = scrollTop.value > 300 && pinnedDistance.value > 1200 && scrollDirection.value === 1;
            footerVisible.value = scrollTop.value > scrollHeight.value - innerHeight.value * 1.5;
        }, false);
    }

    ['resize', 'orientationchange'].forEach((evname) => {
        window.addEventListener(evname, debouncedResize);
    });
}

const isLoading = ref(false);

export const useClientState = () => ({
    langCookie,
    isBot,
    baseName,
    scrollTop,
    scrollDirection,
    pin,
    pinnedDistance,
    scrolledSmall,
    scrolledLarge,
    isLoading,
    footerVisible,
    innerWidth,
    innerHeight,
    navOpened,
    subnavOpened,
    firstPanelIsBlue,
    countryPanelIsVisible,
    setWindowSizes,
    submenuOpened,
    loaded,
    loadingShown,
});
