const getOffset = () => {
    const nav = document.querySelector('.nav-main');
    const countrySelector = document.querySelector('.country-selector');
    const navHeight = nav ? nav.getBoundingClientRect().height : 0;
    const countrySelectorHeight = countrySelector ? countrySelector.getBoundingClientRect().height : 0;
    return (navHeight + countrySelectorHeight) * -1;
};

const lenisScrollTo = (target, options = {}) => {
    const router = useRouter();
    if (target) {
        let realTarget = target;
        // Fix for "sticky" positioned elements
        if (target.dataset.scrollTop) {
            realTarget = parseInt(target.dataset.scrollTop, 10);
        }

        lenis.value.scrollTo(realTarget, {
            offset: getOffset(),
            duration: 2.5,
            onComplete() {
                if (!isServer()) {
                    // window.location.href = `#to=${target.getAttribute('id')}`;
                    router.replace({ hash: `#to=${target.getAttribute('id')}` });
                }
            },
            ...options,
        });
    }
};

export { lenisScrollTo };
