import { ref } from 'vue';
import Lenis from '@studio-freight/lenis';
import Tempus from '@studio-freight/tempus';
import { gsap } from '~/composables/use-gsap.js';
// import { gsap, ScrollTrigger } from '~/composables/use-gsap.js';
// const route = useRoute();

const lenis = ref(null);

const useLenis = (options = { lerp: 0.05 }) => {
    if (!lenis.value) {
        if (!isServer()) {
        // if (!isServer() && !route?.query?.token && !route?.query?.['x-craft-live-preview']) {
            lenis.value = new Lenis(options);
            // lenis.value.on('scroll', () => {
            //     ScrollTrigger.update();
            // });
            gsap.ticker.remove(gsap.updateRoot);
            Tempus.add((time) => {
                gsap.updateRoot(time / 1000);
                lenis.value.raf(time);
            }, 999);
        }
    }

    return lenis;
};

export { useLenis, lenis };
