import { default as _91_46_46_46slug_9397659wmlvoMeta } from "/Users/guimauve/Sites/azimut-site/nuxt/pages/[...slug].vue?macro=true";
import { default as indexrZclkJYQFNMeta } from "/Users/guimauve/Sites/azimut-site/nuxt/pages/index.vue?macro=true";
import { default as _91_46_46_46slug_93ZXjq6B6WNKMeta } from "/Users/guimauve/Sites/azimut-site/nuxt/pages/realisations/[...slug].vue?macro=true";
export default [
  {
    name: _91_46_46_46slug_9397659wmlvoMeta?.name ?? "slug___fr-ca",
    path: _91_46_46_46slug_9397659wmlvoMeta?.path ?? "/:slug(.*)*",
    meta: _91_46_46_46slug_9397659wmlvoMeta || {},
    alias: _91_46_46_46slug_9397659wmlvoMeta?.alias || [],
    redirect: _91_46_46_46slug_9397659wmlvoMeta?.redirect,
    component: () => import("/Users/guimauve/Sites/azimut-site/nuxt/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexrZclkJYQFNMeta?.name ?? "index___fr-ca",
    path: indexrZclkJYQFNMeta?.path ?? "/",
    meta: indexrZclkJYQFNMeta || {},
    alias: indexrZclkJYQFNMeta?.alias || [],
    redirect: indexrZclkJYQFNMeta?.redirect,
    component: () => import("/Users/guimauve/Sites/azimut-site/nuxt/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93ZXjq6B6WNKMeta?.name ?? "realisations-slug___fr-ca",
    path: _91_46_46_46slug_93ZXjq6B6WNKMeta?.path ?? "/realisations/:slug(.*)*",
    meta: _91_46_46_46slug_93ZXjq6B6WNKMeta || {},
    alias: _91_46_46_46slug_93ZXjq6B6WNKMeta?.alias || [],
    redirect: _91_46_46_46slug_93ZXjq6B6WNKMeta?.redirect,
    component: () => import("/Users/guimauve/Sites/azimut-site/nuxt/pages/realisations/[...slug].vue").then(m => m.default || m)
  }
]