import { animate } from 'motion';

const { loaded, loadingShown } = useClientState();

export default defineNuxtPlugin((nuxtApp) => {
    nuxtApp.hook('page:loading:end', () => {
        const anim = animate('.loading-screen', {
            opacity: [1, 0],
        }, {
            duration: 1, easing: [0.14, 0.47, 0.45, 0.94], fill: 'both', composite: 'replace', allowWebkitAcceleration: true,
        });
        anim.finished.then(() => {
            loaded.value = true;
            loadingShown.value = false;
        });
    });
});
