import revive_payload_client_4sVQNw7RlN from "/Users/guimauve/Sites/azimut-site/nuxt/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/Users/guimauve/Sites/azimut-site/nuxt/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/Users/guimauve/Sites/azimut-site/nuxt/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/Users/guimauve/Sites/azimut-site/nuxt/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/Users/guimauve/Sites/azimut-site/nuxt/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/Users/guimauve/Sites/azimut-site/nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/Users/guimauve/Sites/azimut-site/nuxt/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_LcKgStRyi6 from "/Users/guimauve/Sites/azimut-site/nuxt/node_modules/nuxt-gtag/dist/runtime/plugin.client.mjs";
import i18n_yfWm7jX06p from "/Users/guimauve/Sites/azimut-site/nuxt/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_jBToDIZpFa from "/Users/guimauve/Sites/azimut-site/nuxt/node_modules/nuxt-graphql-client/dist/runtime/plugin.mjs";
import chunk_reload_client_UciE0i6zes from "/Users/guimauve/Sites/azimut-site/nuxt/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import graphql_error_catcher_BQ0d3151Ft from "/Users/guimauve/Sites/azimut-site/nuxt/plugins/graphql-error-catcher.js";
import iframe_resizer_q8frHkFCIz from "/Users/guimauve/Sites/azimut-site/nuxt/plugins/iframe-resizer.js";
import initial_load_1NTICydksi from "/Users/guimauve/Sites/azimut-site/nuxt/plugins/initial-load.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_client_LcKgStRyi6,
  i18n_yfWm7jX06p,
  plugin_jBToDIZpFa,
  chunk_reload_client_UciE0i6zes,
  graphql_error_catcher_BQ0d3151Ft,
  iframe_resizer_q8frHkFCIz,
  initial_load_1NTICydksi
]