export const useSharedState = () => {
    const navs = useState('navs', () => []);
    const globals = useState('globals', () => []);
    const alternateUrls = useState('alternateUrls', () => []);
    const translations = useState('translations', () => {});
    const groups = useState('groups', () => [{
        id: 2,
        name: 'Canada',
    }]);
    const sites = useState('sites', () => ({
        fr_CA: {
            id: 1,
            name: 'Québec',
            lang: 'fr_CA',
            shortlang: 'fr',
            primary: false,
            prefix: 'fr-ca',
            metalang: 'fr-ca',
            groupId: 2,
        },
        // en_CA: {
        //    id: 2,
        //    name: 'Canada',
        //    lang: 'en_CA',
        //    shortlang: 'en',
        //    metalang: 'en-ca',
        //    primary: true,
        //    prefix: '',
        //    groupId: 2,
        // },
    }));
    const activeSite = useState('activeSite', () => sites.value.en_CA);
    const currentEntry = useState('currentEntry', () => null);

    const setNavs = (navsData = []) => {
        navs.value = navsData;
    };
    const setGlobals = (globalsData = []) => {
        globals.value = globalsData;

        const res = {};
        if (globalsData?.translations?.ui) {
            for (let i = 0, il = globalsData?.translations.ui.length; i < il; ++i) {
                res[globalsData?.translations?.ui[i].label] = globalsData?.translations.ui[i].translation;
            }
        }
        translations.value = res;
    };
    const setAlternateUrls = (alternateUrlsData = []) => {
        const alts = toRaw(alternateUrlsData);
        const res = [];
        for (let i = 0, il = alts.length; i < il; ++i) {
            const alternateUrl = alts[i];
            const { prefix } = sites.value[alternateUrl.siteHandle];
            if (alternateUrl.uri === '__home__') {
                alternateUrl.fixed = `/${prefix}`;
            } else if (prefix === '') {
                alternateUrl.fixed = `/${alternateUrl.uri}`;
            } else {
                alternateUrl.fixed = `/${prefix}/${alternateUrl.uri}`;
            }
            res.push(toRaw(alternateUrl));
        }
        alternateUrls.value = res;
    };
    const setGroups = (groupsData = []) => {
        groups.value = groupsData;
    };
    const setSites = (sitesData = {}) => {
        sites.value = sitesData;
    };
    const setActiveSite = (activeSiteData = {}) => {
        activeSite.value = activeSiteData;
    };
    const setCurrentEntry = (currentEntryData = null) => {
        currentEntry.value = currentEntryData;
        if (currentEntry.value?.localized) {
            setAlternateUrls(currentEntry.value.localized);
        }
    };
    const tt = (label = '') => {
        let res = '';
        if (label && typeof translations.value[label] !== 'undefined') {
            res = translations.value[label];
        } else {
            res = label;
        }

        return res;
    };

    return {
        navs,
        globals,
        alternateUrls,
        sites,
        activeSite,
        groups,
        currentEntry,
        translations,
        setNavs,
        setGlobals,
        setAlternateUrls,
        setGroups,
        setSites,
        setActiveSite,
        setCurrentEntry,
        tt,
    };
};
